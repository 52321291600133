import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import LinkTransition from '../../components/linkTransition';
import Layout from '../../components/layout';
import Footer from '../../components/footer';

import IconArrow from '../../assets/icoArrowLeftBlack.inline.svg';

const InsightsBlog = ({ location, data }) => {
    const { atuoInsights } = data;
    const image = getImage(atuoInsights.remoteImage);

    return (
        <Layout bg="white" location={location}>
            <Helmet>
                <title>ATUO - Insights - {atuoInsights.title}</title>
            </Helmet>
            <div className="relative z-0 min-h-screen">
                <GatsbyImage
                    image={image}
                    className="object-cover min-h-screen"
                    alt={atuoInsights.title}
                />

                <div className="absolute min-h-screen top-0 w-full">
                    <div className="container min-h-screen flex items-center">
                        <h1 className="text-4xl md:text-8xl text-black">
                            {atuoInsights.title}
                        </h1>
                    </div>
                </div>
            </div>

            <div className="container pt-20 md:pt-40 pb-10 md:pb-20">
                <div className="w-full md:w-2/3 mx-auto blog-post">
                    <div
                        dangerouslySetInnerHTML={{ __html: atuoInsights.body }}
                    ></div>
                </div>

                <div className="block md:flex py-14 md:py-10 border-t border-b border-gray-300 mt-24">
                    <LinkTransition
                        to="/insights"
                        className="flex items-center"
                    >
                        <IconArrow className="mr-4" />
                        <span className="text-yellow mr-2">
                            Voltar para Insights
                        </span>
                    </LinkTransition>
                </div>
            </div>

            <Footer bg="white" />
        </Layout>
    );
};

export const query = graphql`
    query InsightPageQuery($slug: String) {
        atuoInsights(slug: { eq: $slug }) {
            author
            banner
            body
            description
            id
            origin
            post_date
            slug
            title
            remoteImage {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: DOMINANT_COLOR
                        layout: CONSTRAINED
                    )
                }
            }
        }
    }
`;

export default InsightsBlog;
